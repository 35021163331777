.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeAndSlideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeAndSlideOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

.fadeAndSlideIn {
  animation: fadeAndSlideIn 0.5s ease-in;
}

/* Define a keyframe animation for the rainbow effect */
@keyframes rainbow {
  0% { color: rgb(253, 85, 85); }
  16.67% { color: rgb(255, 201, 100); }
  33.33% { color: rgb(248, 248, 109); }
  50% { color: rgb(93, 238, 93); }
  66.67% { color: rgb(123, 123, 255); }
  83.33% { color: rgb(181, 78, 255); }
  100% { color: rgb(255, 177, 255); }
}

/* Apply the rainbow effect to text when hovered */
.rainbowTextHover:hover {
  animation: rainbow 1s linear infinite;
}


.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100dvh;
}

.headerText {
  font-weight: bolder;
  color: lavender;
  text-shadow: 1px 1px white, -1px -1px mediumslateblue;
}

.footer {
  padding: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.nyan {
  width: 400px;
  height: 280px;
}

.grid {
  justify-content: center;
}

.gridItem1 {
  justify-content: center;
}

.gridItem2 {
  justify-content: center;
  text-align: center;
}


